import * as Sentry from "@sentry/vue";

function getEnvironment() {
  switch (window.location.hostname) {
    case "app.picsellia.com":
      return "production";
    case "staging.picsellia.com":
      return "staging";
    case "trial.picsellia.com":
      return "trial";
    default:
      return "development";
  }
}

export function setSentryUser({ id, email, username }) {
  if (!process.env.SENTRY_ENABLED) return;
  Sentry.setUser({ id, email, username });
}

export function setSentryContext(key, context) {
  if (!process.env.SENTRY_ENABLED) return;
  Sentry.setContext(key, context);
}

export function setSentryTag(key, value) {
  if (!process.env.SENTRY_ENABLED) return;
  Sentry.setTag(key, value);
}

export function sentryCaptureException(error) {
  if (!process.env.SENTRY_ENABLED) return;
  Sentry.captureException(error);
}

export function initSentry(Vue) {
  if (!process.env.SENTRY_ENABLED) return;

  Sentry.init({
    Vue,
    dsn: "https://a1d79dc1bf934e9695c4d519d572316b@o1044018.ingest.sentry.io/4505466000113664",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.01,
    environment: getEnvironment(),
    tracePropagationTargets: [
      /^https:\/\/staging.picsellia\.com\//,
      /^https:\/\/trial.picsellia\.com\//,
      /^https:\/\/app.picsellia\.com\//,
    ],
  });
}
