/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DataSourceOut from './DataSourceOut';
import DataTypeEnum from './DataTypeEnum';
import DataUploadStatus from './DataUploadStatus';
import MetaOut from './MetaOut';
import MetadataOut from './MetadataOut';
import TagOut from './TagOut';

/**
 * The ExtendedDataOut model module.
 * @module model/ExtendedDataOut
 * @version 1.0.0
 */
class ExtendedDataOut {
    /**
     * Constructs a new <code>ExtendedDataOut</code>.
     * @alias module:model/ExtendedDataOut
     * @param createdAt {Date} 
     * @param updatedAt {Date} 
     * @param id {String} 
     * @param objectName {String} 
     * @param filename {String} 
     * @param contentType {String} 
     * @param type {module:model/DataTypeEnum} 
     * @param uploadStatus {module:model/DataUploadStatus} 
     * @param meta {module:model/MetaOut} 
     * @param metadata {module:model/MetadataOut} 
     * @param presignedUrl {String} 
     * @param thumbnailPresignedUrl {String} 
     * @param datalakeId {String} 
     * @param fileSize {Number} 
     * @param dataSource {module:model/DataSourceOut} 
     * @param tags {Array.<module:model/TagOut>} 
     */
    constructor(createdAt, updatedAt, id, objectName, filename, contentType, type, uploadStatus, meta, metadata, presignedUrl, thumbnailPresignedUrl, datalakeId, fileSize, dataSource, tags) { 
        
        ExtendedDataOut.initialize(this, createdAt, updatedAt, id, objectName, filename, contentType, type, uploadStatus, meta, metadata, presignedUrl, thumbnailPresignedUrl, datalakeId, fileSize, dataSource, tags);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, updatedAt, id, objectName, filename, contentType, type, uploadStatus, meta, metadata, presignedUrl, thumbnailPresignedUrl, datalakeId, fileSize, dataSource, tags) { 
        obj['created_at'] = createdAt;
        obj['updated_at'] = updatedAt;
        obj['id'] = id;
        obj['object_name'] = objectName;
        obj['filename'] = filename;
        obj['content_type'] = contentType;
        obj['type'] = type;
        obj['upload_status'] = uploadStatus;
        obj['meta'] = meta;
        obj['metadata'] = metadata;
        obj['presigned_url'] = presignedUrl;
        obj['thumbnail_presigned_url'] = thumbnailPresignedUrl;
        obj['datalake_id'] = datalakeId;
        obj['file_size'] = fileSize;
        obj['data_source'] = dataSource;
        obj['tags'] = tags;
    }

    /**
     * Constructs a <code>ExtendedDataOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExtendedDataOut} obj Optional instance to populate.
     * @return {module:model/ExtendedDataOut} The populated <code>ExtendedDataOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExtendedDataOut();

            if (data.hasOwnProperty('created_at')) {
                obj['created_at'] = ApiClient.convertToType(data['created_at'], 'Date');
            }
            if (data.hasOwnProperty('updated_at')) {
                obj['updated_at'] = ApiClient.convertToType(data['updated_at'], 'Date');
            }
            if (data.hasOwnProperty('created_by')) {
                obj['created_by'] = ApiClient.convertToType(data['created_by'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('object_name')) {
                obj['object_name'] = ApiClient.convertToType(data['object_name'], 'String');
            }
            if (data.hasOwnProperty('filename')) {
                obj['filename'] = ApiClient.convertToType(data['filename'], 'String');
            }
            if (data.hasOwnProperty('content_type')) {
                obj['content_type'] = ApiClient.convertToType(data['content_type'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = DataTypeEnum.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('upload_status')) {
                obj['upload_status'] = DataUploadStatus.constructFromObject(data['upload_status']);
            }
            if (data.hasOwnProperty('meta')) {
                obj['meta'] = MetaOut.constructFromObject(data['meta']);
            }
            if (data.hasOwnProperty('metadata')) {
                obj['metadata'] = MetadataOut.constructFromObject(data['metadata']);
            }
            if (data.hasOwnProperty('presigned_url')) {
                obj['presigned_url'] = ApiClient.convertToType(data['presigned_url'], 'String');
            }
            if (data.hasOwnProperty('thumbnail_presigned_url')) {
                obj['thumbnail_presigned_url'] = ApiClient.convertToType(data['thumbnail_presigned_url'], 'String');
            }
            if (data.hasOwnProperty('datalake_id')) {
                obj['datalake_id'] = ApiClient.convertToType(data['datalake_id'], 'String');
            }
            if (data.hasOwnProperty('file_size')) {
                obj['file_size'] = ApiClient.convertToType(data['file_size'], 'Number');
            }
            if (data.hasOwnProperty('data_source')) {
                obj['data_source'] = DataSourceOut.constructFromObject(data['data_source']);
            }
            if (data.hasOwnProperty('tags')) {
                obj['tags'] = ApiClient.convertToType(data['tags'], [TagOut]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ExtendedDataOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ExtendedDataOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ExtendedDataOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['created_by'] && !(typeof data['created_by'] === 'string' || data['created_by'] instanceof String)) {
            throw new Error("Expected the field `created_by` to be a primitive type in the JSON string but got " + data['created_by']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['object_name'] && !(typeof data['object_name'] === 'string' || data['object_name'] instanceof String)) {
            throw new Error("Expected the field `object_name` to be a primitive type in the JSON string but got " + data['object_name']);
        }
        // ensure the json data is a string
        if (data['filename'] && !(typeof data['filename'] === 'string' || data['filename'] instanceof String)) {
            throw new Error("Expected the field `filename` to be a primitive type in the JSON string but got " + data['filename']);
        }
        // ensure the json data is a string
        if (data['content_type'] && !(typeof data['content_type'] === 'string' || data['content_type'] instanceof String)) {
            throw new Error("Expected the field `content_type` to be a primitive type in the JSON string but got " + data['content_type']);
        }
        // validate the optional field `meta`
        if (data['meta']) { // data not null
          MetaOut.validateJSON(data['meta']);
        }
        // validate the optional field `metadata`
        if (data['metadata']) { // data not null
          MetadataOut.validateJSON(data['metadata']);
        }
        // ensure the json data is a string
        if (data['presigned_url'] && !(typeof data['presigned_url'] === 'string' || data['presigned_url'] instanceof String)) {
            throw new Error("Expected the field `presigned_url` to be a primitive type in the JSON string but got " + data['presigned_url']);
        }
        // ensure the json data is a string
        if (data['thumbnail_presigned_url'] && !(typeof data['thumbnail_presigned_url'] === 'string' || data['thumbnail_presigned_url'] instanceof String)) {
            throw new Error("Expected the field `thumbnail_presigned_url` to be a primitive type in the JSON string but got " + data['thumbnail_presigned_url']);
        }
        // ensure the json data is a string
        if (data['datalake_id'] && !(typeof data['datalake_id'] === 'string' || data['datalake_id'] instanceof String)) {
            throw new Error("Expected the field `datalake_id` to be a primitive type in the JSON string but got " + data['datalake_id']);
        }
        // validate the optional field `data_source`
        if (data['data_source']) { // data not null
          DataSourceOut.validateJSON(data['data_source']);
        }
        if (data['tags']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['tags'])) {
                throw new Error("Expected the field `tags` to be an array in the JSON data but got " + data['tags']);
            }
            // validate the optional field `tags` (array)
            for (const item of data['tags']) {
                TagOut.validateJSON(item);
            };
        }

        return true;
    }


}

ExtendedDataOut.RequiredProperties = ["created_at", "updated_at", "id", "object_name", "filename", "content_type", "type", "upload_status", "meta", "metadata", "presigned_url", "thumbnail_presigned_url", "datalake_id", "file_size", "data_source", "tags"];

/**
 * @member {Date} created_at
 */
ExtendedDataOut.prototype['created_at'] = undefined;

/**
 * @member {Date} updated_at
 */
ExtendedDataOut.prototype['updated_at'] = undefined;

/**
 * @member {String} created_by
 */
ExtendedDataOut.prototype['created_by'] = undefined;

/**
 * @member {String} id
 */
ExtendedDataOut.prototype['id'] = undefined;

/**
 * @member {String} object_name
 */
ExtendedDataOut.prototype['object_name'] = undefined;

/**
 * @member {String} filename
 */
ExtendedDataOut.prototype['filename'] = undefined;

/**
 * @member {String} content_type
 */
ExtendedDataOut.prototype['content_type'] = undefined;

/**
 * @member {module:model/DataTypeEnum} type
 */
ExtendedDataOut.prototype['type'] = undefined;

/**
 * @member {module:model/DataUploadStatus} upload_status
 */
ExtendedDataOut.prototype['upload_status'] = undefined;

/**
 * @member {module:model/MetaOut} meta
 */
ExtendedDataOut.prototype['meta'] = undefined;

/**
 * @member {module:model/MetadataOut} metadata
 */
ExtendedDataOut.prototype['metadata'] = undefined;

/**
 * @member {String} presigned_url
 */
ExtendedDataOut.prototype['presigned_url'] = undefined;

/**
 * @member {String} thumbnail_presigned_url
 */
ExtendedDataOut.prototype['thumbnail_presigned_url'] = undefined;

/**
 * @member {String} datalake_id
 */
ExtendedDataOut.prototype['datalake_id'] = undefined;

/**
 * @member {Number} file_size
 */
ExtendedDataOut.prototype['file_size'] = undefined;

/**
 * @member {module:model/DataSourceOut} data_source
 */
ExtendedDataOut.prototype['data_source'] = undefined;

/**
 * @member {Array.<module:model/TagOut>} tags
 */
ExtendedDataOut.prototype['tags'] = undefined;






export default ExtendedDataOut;

