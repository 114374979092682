<template>
  <li @click="onClick" class="nav-bar-link">
    <a v-if="href" :href="href" :target="target">
      <icon :name="iconName" class="nav-bar-icon" />
    </a>
    <icon v-else :name="iconName" class="nav-bar-icon" />
    <ul class="nav-bar-link-details" :class="{ reverse, 'align-second-item': alignSecondItem }">
      <slot />
    </ul>
  </li>
</template>

<script>
import Icon from "@/components/icons/Icon.vue";

export default {
  name: "NavBarLink",
  props: {
    iconName: { type: String },
    target: { type: String, default: "_self" },
    href: { type: String, default: "" },
    reverse: { type: Boolean, default: false },
    alignSecondItem: { type: Boolean, default: false },
  },
  components: { Icon },
  methods: {
    onClick() {
      if (!this.disabled) this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.nav-bar-link {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 35px;
  font-weight: 400;
  width: 100%;
  margin-bottom: 10px;
  z-index: 1000;

  .nav-bar-icon {
    height: 16px;
    width: 16px;
    margin: auto;
    fill: #9a969d;
  }

  &:hover {
    background-color: #171817;

    .nav-bar-link-details {
      display: block;
    }

    .nav-bar-icon {
      fill: #32ab69;
    }
  }

  a {
    display: flex;
    width: 100%;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
  }

  .nav-bar-link-details {
    display: none;
    position: absolute;
    top: 0px;
    left: 50px;
    min-width: 150px;
    background-color: #171817;
    box-shadow:
      0 0 1px rgba(0, 0, 0, 0.1),
      0 2px 8px rgba(0, 0, 0, 0.8);
    padding: 0;

    &.align-second-item {
      top: -35px;
    }

    &.reverse {
      top: auto;
      bottom: 0;

      &.align-second-item {
        bottom: -35px;
      }
    }
  }
}
</style>
