<template>
  <li
    class="nav-bar-link-detail"
    :class="{ 'no-interaction': noInteraction, danger, selected, disabled }"
    @click="onClick"
  >
    <a v-if="href" :href="href" :target="target" :disabled="disabled"><slot /></a>
    <p v-else><slot /></p>
  </li>
</template>

<script>
export default {
  name: "NavBarDetailLink",
  props: {
    href: { type: String, default: "" },
    target: { type: String, default: "_self" },
    danger: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
  },
  emits: ["click"],
  computed: {
    noInteraction() {
      return !this.href;
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.nav-bar-link-detail {
  height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 12px !important;
  width: 100%;
  position: relative;
  cursor: pointer;

  a,
  p {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    font-size: 12px !important;
    color: var(--white);
    text-decoration: none;
  }

  p {
    cursor: default;
    user-select: none;
  }

  &.no-interaction {
    cusor: default;
    background: #666565;
  }

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;

    a,
    p {
      user-select: none;
      pointer-events: none;
    }
  }

  &.danger {
    background: #f44336;
  }

  &:not(.no-interaction):not(.danger):not(.disabled) {
    &:hover,
    &.selected {
      background-color: #6aac88;
    }
  }
}
</style>
