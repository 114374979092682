var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "nav-bar" },
    [
      _c("UnsupportedModals"),
      _vm._v(" "),
      _c("GlobalLoader", { attrs: { loading: _vm.loading } }),
      _vm._v(" "),
      _c("div", { staticClass: "nav-bar-logo" }, [
        _c(
          "a",
          { attrs: { href: `/${_vm.organizationId}/dashboard` } },
          [
            _c("Icon", {
              staticClass: "nav-bar-logo-icon",
              attrs: { name: "brand-picsellia" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.user?.collaborator?.role !== "unprivileged"
        ? _c(
            "ul",
            { staticClass: "nav-bar-links" },
            [
              _c(
                "nav-bar-link",
                {
                  attrs: {
                    href: `/${_vm.organizationId}/datalake/${_vm.defaultDatalake.id}`,
                    "icon-name": "database",
                  },
                },
                [
                  _vm.hasOtherDatalakes
                    ? _c("nav-bar-detail-link", [_vm._v("Datalakes")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "nav-bar-detail-link",
                    {
                      attrs: {
                        href: `/${_vm.organizationId}/datalake/${_vm.defaultDatalake.id}`,
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.hasOtherDatalakes ? "Default" : "Datalake"
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.datalakes, function (datalake) {
                    return _c(
                      "nav-bar-detail-link",
                      {
                        key: datalake.id,
                        staticClass: "datalake-link",
                        attrs: {
                          href: `/${_vm.organizationId}/datalake/${datalake.id}`,
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(datalake.name) + "\n      "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "nav-bar-link",
                {
                  attrs: {
                    href: `/${_vm.organizationId}/dataset/list`,
                    "icon-name": "image",
                  },
                },
                [
                  _c(
                    "nav-bar-detail-link",
                    { attrs: { href: `/${_vm.organizationId}/dataset/list` } },
                    [_vm._v("Datasets")]
                  ),
                  _vm._v(" "),
                  _c(
                    "nav-bar-detail-link",
                    {
                      attrs: {
                        href: `/${_vm.organizationId}/dataset/processings/list`,
                      },
                    },
                    [_vm._v("Processings")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "nav-bar-link",
                {
                  attrs: {
                    href: `/${_vm.organizationId}/project/list`,
                    "icon-name": "project-board",
                  },
                },
                [
                  _c(
                    "nav-bar-detail-link",
                    { attrs: { href: `/${_vm.organizationId}/project/list` } },
                    [_vm._v("Projects")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "nav-bar-link",
                {
                  attrs: {
                    "icon-name": "stack",
                    href: `/${_vm.organizationId}/model/list`,
                  },
                },
                [
                  _c(
                    "nav-bar-detail-link",
                    { attrs: { href: `/${_vm.organizationId}/model/list` } },
                    [_vm._v("Models")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "nav-bar-link",
                {
                  attrs: {
                    "icon-name": "package-dependents",
                    href: `/${_vm.organizationId}/deployment/list`,
                  },
                },
                [
                  _c(
                    "nav-bar-detail-link",
                    {
                      attrs: {
                        href: `/${_vm.organizationId}/deployment/list`,
                        title: _vm.isPremium ? "" : "Please upgrade your plan",
                        disabled: !_vm.isPremium,
                      },
                    },
                    [_vm._v("\n        Deployments\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "nav-bar-footer" },
        [
          _vm.user?.collaborator?.role !== "unprivileged"
            ? _c(
                "nav-bar-link",
                {
                  attrs: {
                    href: `/${_vm.organizationId}/jobs`,
                    "icon-name": "play",
                  },
                },
                [
                  _c(
                    "nav-bar-detail-link",
                    {
                      attrs: {
                        href: `/${_vm.organizationId}/jobs?type=processings`,
                      },
                    },
                    [_vm._v("Jobs")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "nav-bar-link",
            {
              attrs: {
                href: "https://documentation.picsellia.com",
                "icon-name": "book",
                target: "_blank",
              },
            },
            [
              _c(
                "nav-bar-detail-link",
                {
                  attrs: {
                    href: "https://documentation.picsellia.com",
                    target: "_blank",
                  },
                },
                [_vm._v("\n        Documentation\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "nav-bar-link",
            { attrs: { "icon-name": "gear", reverse: "" } },
            [
              _c("div", { staticClass: "nav-bar-link-detail-user" }, [
                _vm.user.picture_url
                  ? _c("img", {
                      staticClass: "user-picture",
                      attrs: {
                        src: _vm.user.picture_url,
                        alt: _vm.user.name,
                        loading: "lazy",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "session-info" }, [
                  _c("p", { staticClass: "user-name" }, [
                    _vm._v(_vm._s(_vm.user.username)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "organisation-name" }, [
                    _vm._v(_vm._s(_vm.currentOrganization.name)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "nav-bar-detail-link",
                {
                  attrs: {
                    href: `/${_vm.organizationId}/settings/user/profile`,
                  },
                },
                [_vm._v("\n        Personal Settings\n      ")]
              ),
              _vm._v(" "),
              _vm.organizationId === _vm.myOrganization.id ||
              _vm.currentCollaborator.role === "admin"
                ? _c(
                    "nav-bar-detail-link",
                    {
                      attrs: {
                        href: `/${_vm.organizationId}/settings/organization/general`,
                      },
                    },
                    [_vm._v("\n        Organization Settings\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.organizations.length > 1
                ? _c("nav-bar-detail-link", [_vm._v("Switch Organization")])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.organizations, function (organization) {
                return _vm.organizations.length > 1
                  ? _c(
                      "nav-bar-detail-link",
                      {
                        key: organization.id,
                        attrs: {
                          href: `/${organization.id}/dashboard`,
                          selected: organization.id === _vm.organizationId,
                        },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(organization.name) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e()
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "nav-bar-link",
            {
              attrs: { "icon-name": "sign-out" },
              on: { click: _vm.onSignOut },
            },
            [
              _c(
                "nav-bar-detail-link",
                { attrs: { danger: "" }, on: { click: _vm.onSignOut } },
                [_vm._v("Logout")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }