var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isUnsupportedDevice || _vm.isUnsupportedBrowser
    ? _c("div", { staticClass: "unsupported-modals" }, [
        _vm.isUnsupportedDevice
          ? _c(
              "div",
              {
                staticClass: "modal",
                attrs: { tabindex: "-1", role: "dialog" },
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isUnsupportedBrowser
          ? _c(
              "div",
              {
                staticClass: "modal",
                attrs: { tabindex: "-1", role: "dialog" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog modal-dialog-centered",
                    attrs: { role: "document" },
                  },
                  [
                    _c("div", { staticClass: "modal-content" }, [
                      _c("div", { staticClass: "modal-body bg-white" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-around mt-3" },
                          [
                            _c("img", {
                              staticStyle: { width: "200px", height: "200px" },
                              attrs: {
                                src: require("@/img/firefox.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticStyle: { width: "200px", height: "200px" },
                              attrs: {
                                src: require("@/img/chrome.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(1),
                      ]),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal-dialog modal-dialog-centered",
        attrs: { role: "document" },
      },
      [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-body" }, [
            _c("p", { staticClass: "h4 text-forest" }, [
              _vm._v(
                "\n            The platform is not optimized for a comfortable visit on mobile. "
              ),
              _c("br"),
              _vm._v("\n            Please come back on desktop !\n          "),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "h4 text-midnight text-center mt-3" }, [
      _vm._v("\n            Our platform is optimized for "),
      _c("b", [_vm._v("Firefox")]),
      _vm._v(" or "),
      _c("b", [_vm._v("Chrome")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n            Please come back on either one of this browser\n          "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }