var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { staticClass: "nav-bar-link", on: { click: _vm.onClick } },
    [
      _vm.href
        ? _c(
            "a",
            { attrs: { href: _vm.href, target: _vm.target } },
            [
              _c("icon", {
                staticClass: "nav-bar-icon",
                attrs: { name: _vm.iconName },
              }),
            ],
            1
          )
        : _c("icon", {
            staticClass: "nav-bar-icon",
            attrs: { name: _vm.iconName },
          }),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "nav-bar-link-details",
          class: {
            reverse: _vm.reverse,
            "align-second-item": _vm.alignSecondItem,
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }