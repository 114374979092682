/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DataTypeEnum from './DataTypeEnum';
import DataUploadStatus from './DataUploadStatus';
import MetaOut from './MetaOut';
import MetadataOut from './MetadataOut';

/**
 * The DataOut model module.
 * @module model/DataOut
 * @version 1.0.0
 */
class DataOut {
    /**
     * Constructs a new <code>DataOut</code>.
     * @alias module:model/DataOut
     * @param id {String} 
     * @param objectName {String} 
     * @param filename {String} 
     * @param contentType {String} 
     * @param type {module:model/DataTypeEnum} 
     * @param uploadStatus {module:model/DataUploadStatus} 
     * @param meta {module:model/MetaOut} 
     * @param metadata {module:model/MetadataOut} 
     * @param presignedUrl {String} 
     * @param thumbnailPresignedUrl {String} 
     */
    constructor(id, objectName, filename, contentType, type, uploadStatus, meta, metadata, presignedUrl, thumbnailPresignedUrl) { 
        
        DataOut.initialize(this, id, objectName, filename, contentType, type, uploadStatus, meta, metadata, presignedUrl, thumbnailPresignedUrl);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, objectName, filename, contentType, type, uploadStatus, meta, metadata, presignedUrl, thumbnailPresignedUrl) { 
        obj['id'] = id;
        obj['object_name'] = objectName;
        obj['filename'] = filename;
        obj['content_type'] = contentType;
        obj['type'] = type;
        obj['upload_status'] = uploadStatus;
        obj['meta'] = meta;
        obj['metadata'] = metadata;
        obj['presigned_url'] = presignedUrl;
        obj['thumbnail_presigned_url'] = thumbnailPresignedUrl;
    }

    /**
     * Constructs a <code>DataOut</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DataOut} obj Optional instance to populate.
     * @return {module:model/DataOut} The populated <code>DataOut</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DataOut();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('object_name')) {
                obj['object_name'] = ApiClient.convertToType(data['object_name'], 'String');
            }
            if (data.hasOwnProperty('filename')) {
                obj['filename'] = ApiClient.convertToType(data['filename'], 'String');
            }
            if (data.hasOwnProperty('content_type')) {
                obj['content_type'] = ApiClient.convertToType(data['content_type'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = DataTypeEnum.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('upload_status')) {
                obj['upload_status'] = DataUploadStatus.constructFromObject(data['upload_status']);
            }
            if (data.hasOwnProperty('meta')) {
                obj['meta'] = MetaOut.constructFromObject(data['meta']);
            }
            if (data.hasOwnProperty('metadata')) {
                obj['metadata'] = MetadataOut.constructFromObject(data['metadata']);
            }
            if (data.hasOwnProperty('presigned_url')) {
                obj['presigned_url'] = ApiClient.convertToType(data['presigned_url'], 'String');
            }
            if (data.hasOwnProperty('thumbnail_presigned_url')) {
                obj['thumbnail_presigned_url'] = ApiClient.convertToType(data['thumbnail_presigned_url'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DataOut</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DataOut</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DataOut.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['object_name'] && !(typeof data['object_name'] === 'string' || data['object_name'] instanceof String)) {
            throw new Error("Expected the field `object_name` to be a primitive type in the JSON string but got " + data['object_name']);
        }
        // ensure the json data is a string
        if (data['filename'] && !(typeof data['filename'] === 'string' || data['filename'] instanceof String)) {
            throw new Error("Expected the field `filename` to be a primitive type in the JSON string but got " + data['filename']);
        }
        // ensure the json data is a string
        if (data['content_type'] && !(typeof data['content_type'] === 'string' || data['content_type'] instanceof String)) {
            throw new Error("Expected the field `content_type` to be a primitive type in the JSON string but got " + data['content_type']);
        }
        // validate the optional field `meta`
        if (data['meta']) { // data not null
          MetaOut.validateJSON(data['meta']);
        }
        // validate the optional field `metadata`
        if (data['metadata']) { // data not null
          MetadataOut.validateJSON(data['metadata']);
        }
        // ensure the json data is a string
        if (data['presigned_url'] && !(typeof data['presigned_url'] === 'string' || data['presigned_url'] instanceof String)) {
            throw new Error("Expected the field `presigned_url` to be a primitive type in the JSON string but got " + data['presigned_url']);
        }
        // ensure the json data is a string
        if (data['thumbnail_presigned_url'] && !(typeof data['thumbnail_presigned_url'] === 'string' || data['thumbnail_presigned_url'] instanceof String)) {
            throw new Error("Expected the field `thumbnail_presigned_url` to be a primitive type in the JSON string but got " + data['thumbnail_presigned_url']);
        }

        return true;
    }


}

DataOut.RequiredProperties = ["id", "object_name", "filename", "content_type", "type", "upload_status", "meta", "metadata", "presigned_url", "thumbnail_presigned_url"];

/**
 * @member {String} id
 */
DataOut.prototype['id'] = undefined;

/**
 * @member {String} object_name
 */
DataOut.prototype['object_name'] = undefined;

/**
 * @member {String} filename
 */
DataOut.prototype['filename'] = undefined;

/**
 * @member {String} content_type
 */
DataOut.prototype['content_type'] = undefined;

/**
 * @member {module:model/DataTypeEnum} type
 */
DataOut.prototype['type'] = undefined;

/**
 * @member {module:model/DataUploadStatus} upload_status
 */
DataOut.prototype['upload_status'] = undefined;

/**
 * @member {module:model/MetaOut} meta
 */
DataOut.prototype['meta'] = undefined;

/**
 * @member {module:model/MetadataOut} metadata
 */
DataOut.prototype['metadata'] = undefined;

/**
 * @member {String} presigned_url
 */
DataOut.prototype['presigned_url'] = undefined;

/**
 * @member {String} thumbnail_presigned_url
 */
DataOut.prototype['thumbnail_presigned_url'] = undefined;






export default DataOut;

