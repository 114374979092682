<template>
  <nav class="nav-bar">
    <UnsupportedModals />
    <GlobalLoader :loading="loading" />
    <div class="nav-bar-logo">
      <a :href="`/${organizationId}/dashboard`">
        <Icon class="nav-bar-logo-icon" name="brand-picsellia" />
      </a>
    </div>
    <ul v-if="user?.collaborator?.role !== 'unprivileged'" class="nav-bar-links">
      <nav-bar-link :href="`/${organizationId}/datalake/${defaultDatalake.id}`" icon-name="database">
        <nav-bar-detail-link v-if="hasOtherDatalakes">Datalakes</nav-bar-detail-link>
        <nav-bar-detail-link :href="`/${organizationId}/datalake/${defaultDatalake.id}`">
          {{ hasOtherDatalakes ? "Default" : "Datalake" }}
        </nav-bar-detail-link>
        <nav-bar-detail-link
          class="datalake-link"
          v-for="datalake in datalakes"
          :key="datalake.id"
          :href="`/${organizationId}/datalake/${datalake.id}`"
        >
          {{ datalake.name }}
        </nav-bar-detail-link>
      </nav-bar-link>
      <nav-bar-link :href="`/${organizationId}/dataset/list`" icon-name="image">
        <nav-bar-detail-link :href="`/${organizationId}/dataset/list`">Datasets</nav-bar-detail-link>
        <nav-bar-detail-link :href="`/${organizationId}/dataset/processings/list`">Processings</nav-bar-detail-link>
      </nav-bar-link>
      <nav-bar-link :href="`/${organizationId}/project/list`" icon-name="project-board">
        <nav-bar-detail-link :href="`/${organizationId}/project/list`">Projects</nav-bar-detail-link>
      </nav-bar-link>
      <nav-bar-link icon-name="stack" :href="`/${organizationId}/model/list`">
        <nav-bar-detail-link :href="`/${organizationId}/model/list`">Models</nav-bar-detail-link>
      </nav-bar-link>
      <nav-bar-link icon-name="package-dependents" :href="`/${organizationId}/deployment/list`">
        <nav-bar-detail-link
          :href="`/${organizationId}/deployment/list`"
          :title="isPremium ? '' : 'Please upgrade your plan'"
          :disabled="!isPremium"
        >
          Deployments
        </nav-bar-detail-link>
      </nav-bar-link>
    </ul>
    <ul class="nav-bar-footer">
      <nav-bar-link
        v-if="user?.collaborator?.role !== 'unprivileged'"
        :href="`/${organizationId}/jobs`"
        icon-name="play"
      >
        <nav-bar-detail-link :href="`/${organizationId}/jobs?type=processings`">Jobs</nav-bar-detail-link>
      </nav-bar-link>
      <nav-bar-link href="https://documentation.picsellia.com" icon-name="book" target="_blank">
        <nav-bar-detail-link href="https://documentation.picsellia.com" target="_blank">
          Documentation
        </nav-bar-detail-link>
      </nav-bar-link>
      <nav-bar-link icon-name="gear" reverse>
        <div class="nav-bar-link-detail-user">
          <img class="user-picture" v-if="user.picture_url" :src="user.picture_url" :alt="user.name" loading="lazy" />
          <div class="session-info">
            <p class="user-name">{{ user.username }}</p>
            <p class="organisation-name">{{ currentOrganization.name }}</p>
          </div>
        </div>
        <nav-bar-detail-link :href="`/${organizationId}/settings/user/profile`">
          Personal Settings
        </nav-bar-detail-link>
        <nav-bar-detail-link
          :href="`/${organizationId}/settings/organization/general`"
          v-if="organizationId === myOrganization.id || currentCollaborator.role === 'admin'"
        >
          Organization Settings
        </nav-bar-detail-link>
        <nav-bar-detail-link v-if="organizations.length > 1">Switch Organization</nav-bar-detail-link>
        <nav-bar-detail-link
          v-if="organizations.length > 1"
          v-for="organization of organizations"
          :key="organization.id"
          :href="`/${organization.id}/dashboard`"
          :selected="organization.id === organizationId"
        >
          {{ organization.name }}
        </nav-bar-detail-link>
      </nav-bar-link>
      <nav-bar-link icon-name="sign-out" @click="onSignOut">
        <nav-bar-detail-link @click="onSignOut" danger>Logout</nav-bar-detail-link>
      </nav-bar-link>
    </ul>
  </nav>
</template>

<script>
import NavBarLink from "@/components/Navbar/Link";
import NavBarDetailLink from "@/components/Navbar/DetailLink";
import GlobalLoader from "@/components/Navbar/GlobalLoader";
import UnsupportedModals from "@/components/Navbar/UnsupportedModals";
import Icon from "@/components/icons/Icon";
import DatalakeApi from "@/js/api/DatalakeApi.js";
import OrganizationApi from "@/js/api/OrganizationApi.js";
import PaymentApi from "@/js/api/PaymentApi.js";
import UserApi from "@/js/api/UserApi.js";
import { setSentryContext, setSentryTag, setSentryUser } from "@/js/sentry";
import AuthApi from "@/js/api/AuthApi.js";

const [, organizationId] = location.pathname.split("/");

export default {
  name: "NavBar",
  components: { GlobalLoader, UnsupportedModals, NavBarLink, NavBarDetailLink, Icon },
  data() {
    return {
      organizationId,
      myOrganization: {},
      currentOrganization: { name: "" },
      currentCollaborator: {},
      organizations: [],
      datalakes: [],
      defaultDatalake: { id: "", name: "" },
      plan: { type: "FREE" },
      user: {
        picture: "",
        name: "",
      },
      loading: true,
    };
  },
  mounted() {
    this.initNavBar();
  },
  computed: {
    isPremium() {
      return this.plan.type === "PREMIUM";
    },
    hasOtherDatalakes() {
      return this.datalakes.length;
    },
  },
  methods: {
    async onSignOut() {
      await AuthApi.logout();
      location.href = "/signin";
    },
    async initNavBar() {
      await Promise.all([this.fetchUser(), this.fetchOrganizations(), this.fetchOrganizationPlan()]);
      await this.fetchCollaborator();
      await this.fetchCurrentOrganization();
      if (this.user.collaborator.role !== "unprivileged") await this.fetchDatalakes();
    },
    async fetchCurrentOrganization() {
      this.currentOrganization = await OrganizationApi.getOrganization(this.organizationId);
      setSentryTag("organization", this.organizationId);
      setSentryContext("organization", { id: this.organizationId, name: this.currentOrganization.name });
      this.currentCollaborator =
        this.currentOrganization.collaborators.find((collab) => collab.user_id === this.user.id) ?? {};
    },

    async fetchOrganizations() {
      const { mine, others } = await OrganizationApi.listOrganizations();
      this.myOrganization = mine;
      this.organizations = others;
      this.organizations.push(mine);
    },

    async fetchDatalakes() {
      const { items: datalakes } = await OrganizationApi.listOrganizationDatalakes(organizationId, { limit: 5 });
      this.defaultDatalake = await this.getDefaultDatalake(datalakes);
      this.datalakes = datalakes.filter((datalake) => datalake.id !== this.currentOrganization["default_datalake_id"]);
    },

    async getDefaultDatalake(datalakes) {
      let defaultDatalake = datalakes.find(({ id }) => id === this.currentOrganization["default_datalake_id"]);
      if (!defaultDatalake)
        defaultDatalake = await DatalakeApi.getDatalake(this.currentOrganization["default_datalake_id"]);
      return defaultDatalake;
    },

    async fetchUser() {
      this.user = await UserApi.getMyUser();
      setSentryUser(this.user);
    },

    async fetchCollaborator() {
      this.user.collaborator = await OrganizationApi.getMyCollaborator(this.organizationId);
      this.loading = false;
    },

    async fetchOrganizationPlan() {
      this.plan = await PaymentApi.getOrganizationPlan(this.organizationId);
    },
  },
};
</script>

<style scoped lang="scss">
.nav-bar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50px;
  flex-shrink: 0;
  background: linear-gradient(#303d36, #171817);
  padding: 0;
  box-shadow: 2px 0 5px 1px rgba(95, 95, 95, 0.1);
  z-index: 1000 !important;

  .nav-bar-logo {
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-top: 0.5rem;

    a {
      margin-inline: auto;
    }

    .nav-bar-logo-icon {
      height: 34px;
      width: 29.3px;
    }
  }

  .nav-bar-links {
    max-height: 70vh;
    margin-top: 60px;
    display: flex;
    flex-direction: column;

    .datalake-link {
      text-transform: capitalize;
    }
  }

  .nav-bar-footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 0.25rem;

    .nav-bar-link-detail-user {
      height: 50px;
      background-color: #666565;
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      .user-picture {
        display: block;
        border-radius: 50%;
        margin: auto;
        width: 25px;
        height: 25px;
        flex: 0 0;
      }

      .session-info {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        margin: auto;
      }

      .user-name,
      .organisation-name {
        color: var(--white);
        font-size: 12px !important;
        margin: 0;
        cursor: default;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100px;
      }
    }
  }
}
</style>
