var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("div", { staticClass: "global-loader" }, [
        _c("div", { staticClass: "cover-spin" }),
        _vm._v(" "),
        _c("div", { staticClass: "uni-background" }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }