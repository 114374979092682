var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "nav-bar-link-detail",
      class: {
        "no-interaction": _vm.noInteraction,
        danger: _vm.danger,
        selected: _vm.selected,
        disabled: _vm.disabled,
      },
      on: { click: _vm.onClick },
    },
    [
      _vm.href
        ? _c(
            "a",
            {
              attrs: {
                href: _vm.href,
                target: _vm.target,
                disabled: _vm.disabled,
              },
            },
            [_vm._t("default")],
            2
          )
        : _c("p", [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }