import Vue from "vue";
import NavBar from "@/components/Navbar/NavBar.vue";
import GlobalLoader from "@/components/Navbar/GlobalLoader.vue";
import { initSentry } from "@/js/sentry";

if (window.pageComponent) {
  initSentry(Vue);

  new Vue({
    el: "#app",
    components: {
      NavBar,
      GlobalLoader,
      Page: window.pageComponent,
    },
  });
}
