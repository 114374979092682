<template>
  <div class="global-loader" v-if="loading">
    <div class="cover-spin" />
    <div class="uni-background" />
  </div>
</template>

<script>
export default {
  name: "GlobalLoader",
  props: {
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      windowLoading: true,
    };
  },
  computed: {
    isLoading() {
      return this.loading || this.windowLoading;
    },
  },
  mounted() {
    window.addEventListener("load", this.onLoad);
  },
  beforeDestroy() {
    window.removeEventListener("load", this.onLoad);
  },
  methods: {
    onLoad() {
      this.windowLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@keyframes spin {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

.uni-background {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1040;
  display: block;
}

.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;
  display: block;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: black;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
  }
}
</style>
